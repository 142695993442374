.invoice-list-container {
  width: 98%;
  margin: 0 auto;
  margin-left:1%;
  margin-right:1%;
}

.invoice-list-container h1 {
  font-size: 4.8rem;
}
