a {
  color: #000000;
  text-decoration: none;
}

.logins {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
}

.google-login {
  align-items: center;
  border-radius: 2px;
  display: flex;
  height: 46px;
  width: 230px;
}

.facebook-login {
  align-items: center;
  border-radius: 2px;
  display: flex;
  height: 46px;
  width: 230px;
}

.facebook-login svg {
  margin-left: 12px;
}

.facebook-login span {
  margin-left: 11px;
}
