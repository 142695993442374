.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}

.modal {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 300px;
  min-height: 180px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

.close-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

#close:hover {
  color: red;
}
