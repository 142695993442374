.emptyInvoices {
  width: 420px;
  height: 400px;
  margin-top: -40px;
}
.topText {
  margin-top: 15px;
  font-size: 1.8rem;
margin-left:20px;

}
.btmText {
  font-size: 1.8rem;
  margin-top: -20px;
  margin-left:40px;
}
.align {
  display: flex;
  justify-content: center;
}
@media (max-width: 500px) {
  .emptyInvoices {
    width: 95%;
  }
  .topText {
    margin-left: 20px;
  }
  .btmText {
    margin-left: 40px;
  }
}
