/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* CSS RESET ENDS */

html {
  background: #f2f2f2;
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.landing-get-started-container {
  background: #8bc34a;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
}

.landing-get-started {
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem;
  justify-content: space-around;
  text-align:center;
  max-width: 960px;
  width: 100%;
}

.get-started-text {
  display: flex;
  padding-bottom:10px; 
  justify-content: center;
  width: 100%;
}

.card {
  display: flex;
  margin: 8px;
  min-width: 280px;
}


.billing-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 450px;
  justify-content: space-between;
  padding-top: 20px;
}

/* .mainSettingsContainer {
  width: 100%;
}

.mainSettingsContainer .card-header {
  margin-bottom: 10px;
  border-bottom: 1px solid gray;
  display: flex;
  justify-content: space-around;
}
.mainSettingsContainer .cardDetails {
  width: 50%
}

.mainSettingsContainer .infoContainer {
  align-self: flex-start;
}

.mainSettingsContainer .edit {
  cursor: pointer;
}

.mainSettingsContainer h1 {
  font-size: 2.5rem;
  color: black;
}

.mainSettingsContainer h2 {
  font-size: 1.6rem;
  display: inline;
}

.mainSettingsContainer p {
  font-size: 2.2rem;
  color: black;
  padding-bottom: 10px;
  display: inline;
}

@media only screen and (max-width: 600px) {
  .mainSettingsContainer h2 {
    font-size: 1.2rem;
  }

  .mainSettingsContainer p {
    font-size: 1.6rem
  }

  .mainSettingsContainer .cardDetails {
    width: 75%
  }
} */

.invoice-list-container {
  width: 98%;
  margin: 0 auto;
  margin-left:1%;
  margin-right:1%;
}

.invoice-list-container h1 {
  font-size: 4.8rem;
}

.emptyInvoices {
  width: 420px;
  height: 400px;
  margin-top: -40px;
}
.topText {
  margin-top: 15px;
  font-size: 1.8rem;
margin-left:20px;

}
.btmText {
  font-size: 1.8rem;
  margin-top: -20px;
  margin-left:40px;
}
.align {
  display: flex;
  justify-content: center;
}
@media (max-width: 500px) {
  .emptyInvoices {
    width: 95%;
  }
  .topText {
    margin-left: 20px;
  }
  .btmText {
    margin-left: 40px;
  }
}

.buttons{
    display:flex;
    justify-content:space-evenly;
    margin-top:20px;
}
p{
    font-size: 1.8rem
}

.shippingDiscount {
  background: lightgray;
}
.subtotalTax {
  background: #eff7f2;
}
.total-due {
  background: rgb(224, 123, 105);
}
.amount-paid {
  background: #8bc34a;
}
.box-container {
  display: flex;
  justify-content: space-between;
}
.bottom {
  border-bottom: 1px solid black;
}
.entryName {
  font-size: 3rem;
  color: #8bc34a;
}
p{
  font-size: 2.5rem;
}
.box {
  width: 45%;
  min-height: 220px;
  padding: 8px 0px 8px 8px;
  overflow-wrap: break-word;
}
.top-box{
  width: 100%;
}
.header {
  font-size: 4rem;
}
@media (max-width: 500px) {
  .box-container {
    flex-direction: column;
  }
  .box {
    width: 99%;
    margin: 1%;
  }
  p{
    font-size:2rem,
  }
  .top-box{
  width: 100%;
}
  .mobileBorder {
    border-bottom: 1px solid black;
  }
}
.entryName1 {
   font-size: 2.5rem;
   padding-top: 10px;   
   color: #8bc34a;
   padding-bottom:5px;
}
.btmTable{
   width:100%;
   table-layout: fixed;
}
.btmTopRow{
   margin-top:10px;
   text-align:center;

}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.items{
   color:black;
   font-size:2.3rem;
   text-align:center;
   padding-bottom: 10px;
   word-wrap:break-word
}
.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}

.modal {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 300px;
  min-height: 180px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

.close-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

#close:hover {
  color: red;
}

a {
  color: #000000;
  text-decoration: none;
}

.logins {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
}

.google-login {
  align-items: center;
  border-radius: 2px;
  display: flex;
  height: 46px;
  width: 230px;
}

.facebook-login {
  align-items: center;
  border-radius: 2px;
  display: flex;
  height: 46px;
  width: 230px;
}

.facebook-login svg {
  margin-left: 12px;
}

.facebook-login span {
  margin-left: 11px;
}

.logo {
  border: 1px solid gray;
  background: gray;
  color: white;
  width: 150px;
  height: 50px;
}


span {
  font-size: 17px;
}
tspan {
  font-size: 17px;
}
text {
  font-size: 17px;
}


header .active {
  border-bottom: solid #8bc34a 5px;
}

.create-invoice-container {
  display: flex;
}

.App {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  width: 100%;
}

.routes-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

